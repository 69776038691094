import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MLinkProps } from "src/ui/Link/MLink"

export function LearnMore(props: MLinkProps) {
  const { t, langKeys } = useTranslate()

  return (
    <ExternalLink style={{ whiteSpace: "nowrap" }} {...props}>
      {props.children ? props.children : t(langKeys.learn_more)}
    </ExternalLink>
  )
}
